export default defineNuxtRouteMiddleware((to) => {
  const { status } = useAuth();
  const checkoutStore = useCheckoutStore();

  if (status.value === 'authenticated') {
    return;
  }

  if (to.name === routes.checkout.name) {
    if (checkoutStore.clientEmail) {
      return;
    }
    return navigateTo(routes.registerCheckout.path);
  }

  return navigateTo(routes.register.path);
});
